import { createGlobalStyle } from "styled-components";
import "@fontsource/montserrat";
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'
import "@fontsource/birthstone";

const GlobalStyles = createGlobalStyle`

*,*::before,*::after {
  margin: 0;
  padding: 0;
  background: #FFC4C3;
}

body {
  font-family: sans-serif;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

* {
  color: inherit;
  text-decoration: none;
}

`;

export default GlobalStyles;
