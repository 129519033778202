import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  margin-top:${props => props.marginTop ? props.marginTop : '0px'};
  align-self: center;
  position: relative;
  background: inherit;
  color:inherit;
  z-index: 20;

  display: flex;
  justify-content: center;
  }
`;

const Btn = styled.button`
  display: inline-block;
  width: 264px;
  height: 7.13vh;
  color: ${props => props.textColor};
  background: #FFDFDF;
  opacity: %50;
  outline: none;
  border: none;
  margin-top: -3.33vh;

  font-size:  16pt;
  font-weight: 700;
  font-family: Montserrat;
  border-radius: 64px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;


  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.textColor};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
    background: none;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
  }
`;


const Button = ({ marginTop, text, link }) => {  
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <ButtonContainer marginTop={marginTop}>
      <Btn 
        onClick={handleClick}      
        textColor={'black'}
        text={text}
      >
          {text}
      </Btn>
    </ButtonContainer>
  );
};


export default Button;
