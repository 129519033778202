import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VerticalSeperator from './VerticalSeperator';
import { SHOWCASE_DATA } from '../../config';

const Section = styled.section`
  position: relative;
  width: 100%;
  background: ${(props) => props.background};
`;

const Container = styled.section`
  position: relative;
  background: inherit;
  padding: 0px 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => props.direction};

  @media (max-width: 64em) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0px 20px;
  }
`;

const Text = styled.p`
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-top: ${(props) => (props.firstParagraph ? '0px' : '32px')};
  background: inherit;

  @media (max-width: 64em) {
    font-size: 16px;
    margin-top: ${(props) => (props.firstParagraph ? '0px' : '20px')};
  }
`;

const Header = styled.p`
  font-family: Montserrat;
  font-size: ${(props) => (props.small ? '32px' : '60px')};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: ${(props) => (props.small ? 'center' : 'left')};
  margin-top: ${(props) => (props.firstParagraph ? '0px' : '32px')};
  background: inherit;
  margin-bottom: 32px;

  @media (max-width: 64em) {
    font-size: 16px;
    margin-top: ${(props) => (props.firstParagraph ? '0px' : '20px')};
    text-align: ${(props) => (props.small ? 'center' : 'left')};
  }
`;

const TextBox = styled.div`
  max-width: 44vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: inherit;
  margin: ${(props) => props.margin};
  background: inherit;

  @media (max-width: 64em) {
    max-width: 90vw;
    margin: 0;
  }
`;


const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28vw;
  max-width: 500px;
  height: auto;
  max-height: 100%;
  overflow: hidden;
  border-radius: 40px;
  background: inherit;

  img {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: cover; /* Ensures the image covers the container, maintaining aspect ratio */
    border-radius: inherit;
  }

  @media (max-width: 64em) {
    margin: 20px 8px 32px 8px;
    min-width: 50vw;
    max-width: 100%;
    max-height: 40vh;

    img {
      object-fit: cover; /* Keeps aspect ratio while zooming to cover */
    }
  }
`;

const ShowCase = ({no, header, body, visual}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <Section 
      key={no}
      id={header}
      background={'inherit'}
    >
      <Container 
        key={no}
        direction={no%2 ? 'row' : 'row-reverse'}
      >    
          <TextBox
            margin={no%2 ? '0px 80px 0px 0px' : '0px 0px 0px 80px'}
          >
            {visual && !isMobile &&
              <Header small={true}>{header}</Header> 
            } 
            {
              body.map((paragraph, index) => (
                <Text 
                  firstParagraph={index === 0}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                  key={index}
                />
                )
              )
            }
          </TextBox>
          {visual && isMobile &&
              <Header small={true}>{header}</Header> 
          } 
          {
            visual?
            <ImageBox 
              key={no}
            >
              <img key={no} src={visual}></img>
            </ImageBox>
            :
            <Header>{header}</Header>
          }
      </Container>
      <VerticalSeperator
        overwrite={
          header === SHOWCASE_DATA[SHOWCASE_DATA.length-1].header ?
          `
          margin: 100px 0px 0px -10vw;
          height: 0px;
          // border-bottom: 1px solid #705D71;
          `
          :
          ''
        }
      />
    </Section>
  );
};

export default ShowCase;
