import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Canvas = styled.canvas`
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const StarsCanvas = () => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    contextRef.current = context;

    const resizeCanvas = () => {
      const devicePixelRatio = window.devicePixelRatio || 1;
      const width = canvas.clientWidth * devicePixelRatio;
      const height = canvas.clientHeight * devicePixelRatio;

      canvas.width = width;
      canvas.height = height;
      canvas.style.width = `${canvas.clientWidth}px`;
      canvas.style.height = `${canvas.clientHeight}px`;

      context.scale(devicePixelRatio, devicePixelRatio);
      createStars();
      drawStars();
    };

    const isMobile = () => window.innerWidth < 768;

    const createStar = () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      radius: Math.random() * 1.5,
      alpha: Math.random(),
    });

    const createSpecialStar = () => {
      const baseRadius = isMobile() ? 0.4 : 1.2;
      const additionalRadius = isMobile() ? Math.random() * 1 : Math.random() * 2;
      return {
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: baseRadius + additionalRadius,
        alpha: Math.random() * 0.5 + 0.5,
        hasDiagonals: Math.random() > 0.5, // Randomly determine if it has diagonals
      };
    };

    let stars = [];
    let specialStars = [];

    const createStars = () => {
      stars = Array.from({ length: 333 }, createStar);
      specialStars = Array.from({ length: 33 }, createSpecialStar);
    };

    const drawStars = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      stars.forEach(star => {
        context.fillStyle = `rgba(255, 255, 255, ${star.alpha})`;
        context.beginPath();
        context.arc(star.x, star.y, star.radius, 0, 2 * Math.PI);
        context.fill();
      });

      specialStars.forEach(star => {
        context.strokeStyle = `rgba(255, 255, 255, ${star.alpha})`;
        context.fillStyle = `rgba(255, 255, 255, ${star.alpha})`;

        const lineMultiplier = isMobile() ? 6 : 2.5;
        const diagLineMultiplier = isMobile() ? 3.2 : 1.2;

        const drawWideLine = (x1, y1, x2, y2, width) => {
          const angle = Math.atan2(y2 - y1, x2 - x1);
          context.beginPath();
          context.moveTo(x1 + width * Math.cos(angle + Math.PI / 2), y1 + width * Math.sin(angle + Math.PI / 2));
          context.lineTo(x1 + width * Math.cos(angle - Math.PI / 2), y1 + width * Math.sin(angle - Math.PI / 2));
          context.lineTo(x2, y2);
          context.closePath();
          context.fill();
        };

        // Draw horizontal and vertical lines with taper
        const taperLength = star.radius * lineMultiplier;
        drawWideLine(star.x, star.y, star.x, star.y - taperLength, star.radius / 2);
        drawWideLine(star.x, star.y, star.x, star.y + taperLength, star.radius / 2);
        drawWideLine(star.x, star.y, star.x - taperLength, star.y, star.radius / 2);
        drawWideLine(star.x, star.y, star.x + taperLength, star.y, star.radius / 2);

        // Draw diagonal lines with taper if hasDiagonals is true
        if (star.hasDiagonals) {
          const diagTaperLength = star.radius * diagLineMultiplier;
          drawWideLine(star.x, star.y, star.x - diagTaperLength, star.y - diagTaperLength, star.radius / 2);
          drawWideLine(star.x, star.y, star.x + diagTaperLength, star.y + diagTaperLength, star.radius / 2);
          drawWideLine(star.x, star.y, star.x - diagTaperLength, star.y + diagTaperLength, star.radius / 2);
          drawWideLine(star.x, star.y, star.x + diagTaperLength, star.y - diagTaperLength, star.radius / 2);
        }
      });
    };


    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

export default StarsCanvas;
