import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VerticalSeperator from './VerticalSeperator';
import LOGIN_SCREEN from '../../assets/phone_screen.png';
import StarsCanvas from './StarsCanvas';
import Button from './Button';


const Section = styled.section`
  position: relative;
  background: none;
  width: 100%;

  background: ${props => props.background};
  color: ${props => props.textColor};
  }
`;

const Container = styled.section`
  position: relative;
  background: none;

  display: flex;
  justify-content: center;
  align-items: end;
  background: inherit;
  color:inherit;

  &:last-child {
    align-items: start;
  }

  @media (max-width: 660px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-top: 144px;
    }
  }
`;

const Header = styled.div`
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: inherit;
  color: inherit;
  margin-bottom: 8px;

  @media (max-width: 84em) {
    font-size: 32px;

    @media (max-width: 660px) {
      font-size: 28px;
      width: 90vw;
      color: inherit;
    }
  }
`;

const Text = styled.text`
  font-family: inherit;
  margin-bottom: 24px;
  background: inherit;
  color:inherit;

  @media (max-width: 660px) {
    color: inherit;
  }
`;

const TextBox = styled.div`
  max-width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  color: white;
  margin-top: 32vh;
  margin-left: -4vw;

  background: none;
 
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 84em) {
    padding: 0vh 5vw 10vh 5vw;
    font-size: 24px;
    margin-top: 180px;

    
    @media (max-width: 660px) {
      max-width: 90vw;
      width: 90vw;
      margin-left: 0;
      margin-top: 0;
      padding: 0vh 5vw 10vh 5vw;
      // background: black;
      font-size: 22px;
    }
  }
`;

const ImageBox = styled.img`
  width: auto;
  height: 76vh;
  background: ${props => props.color};
  display: ${props => props.display};
  margin: 10vh 10vw;

  @media (max-width: 84em) {
    width: auto;
    height: 60vh;
    
    @media (max-width: 660px) {
      width: 64vw;
      height: auto;
      margin-top: 0vh;
    }
  }
`;


const Hero = ({update_location, location}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < window.innerHeight);

  const handleResize = () => {
    setIsMobile(window.innerWidth < window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Section
        id={location}
        background={'linear-gradient(to bottom, #000218, #FF7B7B, #FFC4C3)'}
        textColor={'inherit'}
      >
      <StarsCanvas />
      <Container id="Hero"> 
        <TextBox>
            <Header>{isMobile ? 'Red Tent PMDD 🩸' : 'Red Tent PMDD 🩸'}</Header>
            <Text>
              Awareness. Community. Education. Resources. Research.
            </Text>
            <Button 
              marginTop='312px'
              text='JOIN THE WAITLIST'
              link='https://forms.gle/5pLRf5Xd9exziD767'
            />
        </TextBox>
        <ImageBox 
            id={'login_screen'}
            key={'login_screen'}
            display={'block'}
            src={LOGIN_SCREEN}
            color={'initial'}
          />
      </Container>
      {isMobile && 
      <VerticalSeperator
        overwrite='margin: 0;'
      />
      }
    </Section>
  );
};

export default Hero;
